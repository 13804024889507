import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import Home from "./HomePage/Home";
import Intro from "./IntroPage/Intro";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Resources } from "./components/Resources";
import ContactUs from "./components/ContactUs";
import Services from "./components/Services";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
console.log("baseUrl", baseUrl);
ReactDOM.render(
  <Router basename={baseUrl}>
    <Switch>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/">
        <Intro />
      </Route>
    </Switch>
  </Router>,
  rootElement
);
ReactDOM.render(<Resources />, document.getElementById("resource_download"));
ReactDOM.render(<ContactUs />, document.getElementById("contact_us"));
ReactDOM.render(<Services />, document.getElementById("services_container"));

registerServiceWorker();
