import React, { Component } from "react";
import { BannerSection } from "./Sections/BannerSection";

export class SectionContainer extends Component {
  render() {
    return (
      <div>
        <BannerSection></BannerSection>
      </div>
    );
  }
}
