import React, { Component } from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import amp from "../images/panel/amp.png";
import anz from "../images/panel/anz.png";
import bankwest from "../images/panel/bankwest.png";
import bluestone from "../images/panel/bluestone.png";
import citibank from "../images/panel/citibank.png";
import commonwealth from "../images/panel/commonwealth.png";
import homeloans from "../images/panel/homeloans.png";
import ing from "../images/panel/ing.png";
import latrobe from "../images/panel/latrobe.png";
import liberty from "../images/panel/liberty.png";
import macquarie from "../images/panel/macquarie.png";
import medin from "../images/panel/medin.png";
import mkm from "../images/panel/mkm.png";
import nab from "../images/panel/nab.png";
import paramount from "../images/panel/paramount.png";
import pepper from "../images/panel/pepper.png";
import resimac from "../images/panel/resimac.png";
import scottishpacific from "../images/panel/scottish-pacific.png";
import stgeorge from "../images/panel/st-george.png";
import suncorp from "../images/panel/suncorp.png";
import thinktank from "../images/panel/thinktank.png";
import valiant from "../images/panel/valiant.png";
import vow from "../images/panel/vow.png";
import westpac from "../images/panel/westpac.png";

export class Resources extends Component {
  render() {
    const s3url = "https://prolink.s3-ap-southeast-2.amazonaws.com";
    return (
      <Tabs className="resource-form-tabs" defaultActiveKey="panel_lenders">
        <Tab eventKey="panel_lenders" title="Panel Lenders">
          <div className="resource-form-tab-container">
            <Row xs={3} md={6}>
              <Col>
                <img src={anz} alt="anz"></img>
                <br />
                <img src={suncorp} alt="suncrop"></img>
                <br />
                <img src={bluestone} alt="bluestone"></img>
                <br />
                <img src={paramount} alt="paramount"></img>
              </Col>
              <Col>
                <img src={commonwealth} alt="commonwealth"></img>
                <br />
                <img src={amp} alt="amp"></img>
                <br />
                <img src={latrobe} alt="latrobe"></img>
                <br />
                <img src={scottishpacific} alt="scottishpacific"></img>
              </Col>
              <Col>
                <img src={nab} alt="nab"></img>
                <br />
                <img src={ing} alt="ing"></img>
                <br />
                <img src={mkm} alt="mkm"></img>
                <br />
                <img src={valiant} alt="valiant"></img>
              </Col>
              <Col>
                <img src={westpac} alt="westpac"></img>
                <br />
                <img src={citibank} alt="citibank"></img>
                <br />
                <img src={pepper} alt="pepper"></img>
                <br />
                <img src={vow} alt="vow"></img>
              </Col>
              <Col>
                <img src={stgeorge} alt="stgeorge"></img>
                <br />
                <img src={macquarie} alt="macquarie"></img>
                <br />
                <img src={thinktank} alt="thinktank"></img>
                <br />
                <img src={homeloans} alt="homeloans"></img>
              </Col>
              <Col>
                <img src={bankwest} alt="bankwest"></img>
                <br />
                <img src={liberty} alt="liberty"></img>
                <br />
                <img src={medin} alt="medin"></img>
                <br />
                <img src={resimac} alt="resimac"></img>
              </Col>
            </Row>
          </div>
        </Tab>
        <Tab eventKey="discharge_form" title="Discharge Forms">
          <div className="resource-form-tab-container">
            <h1>
              See below for some <span>Discharge forms</span>
            </h1>
            <Row xs={2} sm={4}>
              <Col>
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/ANZ.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ANZ
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Bendigo.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bendigo
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/AMP.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AMP
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/HSBC.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HSBC
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Resimac.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resimac
                </a>
              </Col>
              <Col>
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/NAB.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAB
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Citibank.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Citibank
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Bankwest.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bankwest
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/La-Trobe.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La Trobe
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/UBank.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ubank
                </a>
              </Col>
              <Col>
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/BOQ.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bank of Queensland
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Macquarie.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Macquarie
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Bluestone.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bluestone
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/MKM-Capital.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MKM Capital
                </a>
                <br />
                <i aria-hidden="true" className="far fa-file-pdf"></i>
                <a
                  href={s3url + "/Liberty.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Liberty
                </a>
              </Col>
              <Col>
                <i aria-hidden="true" className="fas fa-external-link-alt"></i>
                <a
                  href="https://www.commbank.com.au/digital/home-loan-forms/mortgage-discharge/application-form"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Commonweath Bank
                </a>
                <br />
                <i aria-hidden="true" className="fas fa-external-link-alt"></i>
                <a
                  href="https://www.westpac.com.au/personal-banking/home-loans/manage-home-loan/changing-your-home-loan-security/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Westpac
                </a>
                <br />
                <i aria-hidden="true" className="fas fa-external-link-alt"></i>
                <a
                  href="https://www.bankofmelbourne.com.au/personal/home-loans/manage/mortgage-security"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bank of Melbourne
                </a>
                <br />
                <i aria-hidden="true" className="fas fa-external-link-alt"></i>
                <a
                  href="https://www.stgeorge.com.au/personal/home-loans/manage/mortgage-security"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  St George
                </a>
                <br />
                <i aria-hidden="true" className="fas fa-external-link-alt"></i>
                <a
                  href="http://www.brokerpedia.com.au/Modules/Utilities/Att.aspx?id=69472&entryID=269302"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Heritage Bank
                </a>
              </Col>
            </Row>
          </div>
        </Tab>
      </Tabs>
    );
  }
}
