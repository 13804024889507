import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class Services extends Component {
  state = {
    imgSrc0: "upload/prolink-services-selected-0.png",
    imgSrc1: "upload/prolink-services-unselected-1.png",
    imgSrc2: "upload/prolink-services-unselected-2.png",
    imgSrc3: "upload/prolink-services-unselected-3.png",
    serviceTitle: "Accounting & Taxation",
    serviceItems: [
      "Taxation advice and planning",
      "Financial management and reporting",
      "Audit",
    ],
  };
  getImageUrl = (srcIndex, selectedIndex) => {
    if (srcIndex === selectedIndex) {
      return "upload/prolink-services-selected-" + srcIndex + ".png";
    } else {
      return "upload/prolink-services-unselected-" + srcIndex + ".png";
    }
  };
  onMouseEntered = (index) => {
    var title = "";
    var items = [];
    if (index === 0) {
      title = "Accounting & Taxation";
      items = [
        "Taxation advice and planning",
        "Financial management and reporting",
        "Audit",
      ];
    } else if (index === 1) {
      title = "Legal";
        items = [
          "Criminal Law",
        "Business law",
        "Corporate advisory services",
        "Property development and conveyancing",
      ];
    } else if (index === 2) {
      title = "Financial Planning";
      items = [
        "Provide financial product advice",
        "Deposit and payment products",
        "Government Debentures, Stocks or Bonds",
        "Life Product",
        "Managed Investment Schemes-including IDPS",
        "Retirement Savings Account Products",
        "Superannuation - All",
      ];
    } else if (index === 3) {
      title = "Business & Investment";
      items = [
        "Business structuring and reconstruction advice",
        "Business strategic planning and succession strategies",
        "Investment advice",
      ];
    } else {
    }
    this.setState({
      imgSrc0: this.getImageUrl(0, index),
      imgSrc1: this.getImageUrl(1, index),
      imgSrc2: this.getImageUrl(2, index),
      imgSrc3: this.getImageUrl(3, index),
      serviceTitle: title,
      serviceItems: items,
    });
  };

  onClicked = (index) => {
    this.setState({
      imgSrc0: this.getImageUrl(0, index),
      imgSrc1: this.getImageUrl(1, index),
      imgSrc2: this.getImageUrl(2, index),
      imgSrc3: this.getImageUrl(3, index),
    });
  };
  render() {
    return (
      <section
        id="services"
        className="elementor-element elementor-element-f9d30fb elementor-section-stretched elementor-section-height-min-height elementor-section-items-top elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section"
        data-id="f9d30fb"
        data-element_type="section"
        data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
      >
        <div className="elementor-background-overlay"></div>
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-1137b19 elementor-column elementor-col-100 elementor-top-column"
              data-id="1137b19"
              data-element_type="column"
              data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-7ffcea1 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                    data-id="7ffcea1"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="prolink-services-title elementor-heading-title elementor-size-default">
                        Professional Services
                      </h2>
                    </div>
                  </div>
                  <section
                    className="elementor-element elementor-element-ecf6194 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                    data-id="ecf6194"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-element elementor-element-69b0882 elementor-column elementor-col-60 elementor-inner-column"
                          data-id="69b0882"
                          data-element_type="column"
                          data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-d08a02b elementor-widget__width-auto elementor-widget elementor-widget-image"
                                data-id="d08a02b"
                                data-element_type="widget"
                                data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":600,"avante_ext_smoove_scalex":{"unit":"px","size":0.7,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.7,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <Row
                                    className="prolink-services-diagram"
                                    xs={3}
                                    md={3}
                                  >
                                    <Col>
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={this.state.imgSrc0}
                                        className="attachment-large size-large"
                                        alt=""
                                        onMouseEnter={() =>
                                          this.onMouseEntered(0)
                                        }
                                        onClick={() => this.onClicked(0)}
                                      />
                                    </Col>
                                    <Col id="prolink-services-middle-top"></Col>
                                    <Col>
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={this.state.imgSrc1}
                                        className="attachment-large size-large"
                                        alt=""
                                        onMouseEnter={() =>
                                          this.onMouseEntered(1)
                                        }
                                        onClick={() => this.onClicked(1)}
                                      />
                                    </Col>
                                    <Col></Col>
                                    <Col id="prolink-services-middle">
                                      <img
                                        width="100%"
                                        height="100%"
                                        src="upload/prolink-services-middle.png"
                                        className="attachment-large size-large"
                                        alt=""
                                      />
                                    </Col>
                                    <Col></Col>
                                    <Col>
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={this.state.imgSrc2}
                                        className="attachment-large size-large"
                                        alt=""
                                        onMouseEnter={() =>
                                          this.onMouseEntered(2)
                                        }
                                        onClick={() => this.onClicked(2)}
                                      />
                                    </Col>
                                    <Col id="prolink-services-middle-bottom"></Col>
                                    <Col>
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={this.state.imgSrc3}
                                        className="attachment-large size-large"
                                        alt=""
                                        onMouseEnter={() =>
                                          this.onMouseEntered(3)
                                        }
                                        onClick={() => this.onClicked(3)}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-de5c11a elementor-column elementor-col-40 elementor-inner-column"
                          data-id="de5c11a"
                          data-element_type="column"
                          data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-2a5224e elementor-widget elementor-widget-heading"
                                data-id="2a5224e"
                                data-element_type="widget"
                                data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                data-widget_type="heading.default"
                              >
                                <div className="prolink-service-box elementor-widget-container">
                                  <div>
                                    <div className="prolink-service-title">
                                      {this.state.serviceTitle}
                                    </div>
                                  </div>
                                  <div className="clear">
                                    {this.state.serviceItems.map(
                                      (serviceItem, i) => {
                                        return (
                                          <div className="prolink-service-item">
                                            <i className="fas fa-angle-double-right"></i>
                                            <span>{serviceItem}</span>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
